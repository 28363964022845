import React, { useState, useEffect } from 'react';
import TitleEffect from "../../../components/TitleEffect";
import PageTitle from "../../../components/PageTitle";
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, Space, Select } from 'antd';
import {
    getCustomerProfile,
    getRequiredActions,
    setCurrentCustomerProfile,
    setIsEditing,
    saveCustomerProfile,
    loadRequiredActionsList
} from '../../../shared/store/slices/customers'
import { setShowRequestNewDocButton } from "../../../shared/store/slices/documents";
import Loading from '../../../components/Loading';
import CustomerDocumentsList from './CustomerDocumentsList';
import { AccountStatusMessage } from "./Utils";

function CustomerProfileEditPage() {
    TitleEffect(PageTitle("Customer Profile"));

    const profiledatabigStyle = {
        display: "flex",
        alignItems: "center",
        marginTop: "-1px"
    };

    const customer = useSelector(state => state.customers.current);
    const requiredActions = useSelector(state => state.customers.requiredActions);
    const href = "mailto:" + customer?.email;
    const maxDOB = moment().subtract(18, 'years');
    const defaultDOB =
        customer?.dateOfBirth !== null && customer?.dateOfBirth !== undefined ?
            moment(customer?.dateOfBirth) : maxDOB;

    const [editCustomer, setEditCustomer] = useState(customer);
    const [isLoading, setIsLoading] = useState(false);
    const [reqActionOptions, setReqActionOptions] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getRequiredActions({
            customerId: customer.id
        }))
        .then((response => {
            dispatch(loadRequiredActionsList(response.payload));
        }));
        console.log(customer);
    }, []);

    useEffect(() => {
        if (requiredActions !== null && requiredActions !== undefined && requiredActions.length > 0) {
            let newReqActOptions = [];

            requiredActions.map((requiredAction) => {
                newReqActOptions.push(<Option key={requiredAction}>{requiredAction}</Option>);
            });

            setReqActionOptions(newReqActOptions);
        }
    }, [requiredActions]);

    const handleCancel = () => {
        dispatch(setIsEditing(false));
    }

    const handleChangeDOB = (date, dateString) => {
        let value = moment(date).format('YYYY-MM-DDTHH:mm:ss');
        setEditCustomer({...editCustomer, dateOfBirth: value});
    }

    const handleChangeAccountStatusReason = (value) => {
        setEditCustomer({...editCustomer, accountStatusReason: value});
    }

    const handleChangeFlag = (event) => {
        let value = event.target.value;
        setEditCustomer({...editCustomer, flag: value === "true"});
    }

    const handleChangeAccessBuyAndSend = (event) => {
        let denied = event.target.value === "true";
        setEditCustomer({...editCustomer, isDeniedBuyAndSend: denied});
    }

    const handleChangeAllowedWithdrawals = (event) => {
        let denied = event.target.value === "true";
        setEditCustomer({...editCustomer, isDeniedWithdrawals: denied});
    }

    const handleChange = (event) => {
        let key = event.target.getAttribute("data-key");
        let value = event.target.value;

        if(!isNaN(value) && key !== "mobile" && key !== "phoneNumber")
            value = parseInt(value);
        switch (key) {
            case 'address.street':
                setEditCustomer(prevState => ({
                    ...prevState,
                    address: {
                        ...prevState.address,
                        street: value
                    }
                }));
                break;
            case 'address.locality':
                setEditCustomer(prevState => ({
                    ...prevState,
                    address: {
                        ...prevState.address,
                        locality: value
                    }
                }));
                break;
            case 'address.region':
                setEditCustomer(prevState => ({
                    ...prevState,
                    address: {
                        ...prevState.address,
                        region: value
                    }
                }));
                break;
            case 'address.country':
                setEditCustomer(prevState => ({
                    ...prevState,
                    address: {
                        ...prevState.address,
                        country: value
                    }
                }));
                break;
            case 'address.postalCode':
                setEditCustomer(prevState => ({
                    ...prevState,
                    address: {
                        ...prevState.address,
                        postalCode: value
                    }
                }));
                break;

            case 'enabled':
                setEditCustomer({...editCustomer, [key]: value === "Yes"});
                break;

            default:
                setEditCustomer({...editCustomer, [key]: value});
                break;
        }
    }

    const handleChangeRequiredActions = (value) => {
        setEditCustomer({...editCustomer, requiredActions: value});
    }

    const saveCustomer = (event) => {
        setIsLoading(true);
        event.preventDefault();

        if(editCustomer?.accountStatus !== 'Closed')
            setEditCustomer({...editCustomer, accountStatusReason: 'None'});

        dispatch(saveCustomerProfile(editCustomer))
            .then(() => {
                dispatch(getCustomerProfile({
                    customerId: editCustomer.id
                }))
                .then((response) =>{
                    dispatch(setCurrentCustomerProfile(response.payload));
                    dispatch(setShowRequestNewDocButton(false));
                    setIsLoading(false);
                    dispatch(setIsEditing(false));
                }).catch((err) => {
                    setIsLoading(false);
                });
            }).catch((err) => {
                setIsLoading(false);
            });
    }

    const { Option } = Select;
    const acctStatReasonOptions = ["None", "CountryBlocked", "DuplicateAccount", "FixedPhone"
        , "Fraud", "Investigating", "IPCountryMismatch", "OFACMatch", "RestrCtryLogin", "Returns"
        , "Risk", "Suspect", "UserRequest", "VOIP", "VPN"]
        .map(v => <Option key={v}>{AccountStatusMessage(v)}</Option>);

    return <>
        {isLoading ? <Loading /> :
        <div className="profilegrid">
            <div  className="profilegrid1">
                <div className="profilelabel">Email</div>
                <div className="profiledatabig" style={profiledatabigStyle}>
                    <a href={href}>{customer?.email}</a>
                    <button className="copybutton" onClick={() => { handleCopy() }}>Copy</button>
                </div>
                <div className="profilelabel">First Name</div>
                <div>
                    <input className="profiledatabig editinplace" placeholder={customer?.givenName} data-key="givenName" onChange={ handleChange }/>
                </div>
                <div className="profilelabel">Last Name</div>
                <div>
                    <input className="profiledatabig editinplace" placeholder={customer?.familyName} data-key="familyName" onChange={ handleChange }/>
                </div>
            </div>
            <div className="profilegrid2">
                <div className="profilelabel">Available Balance</div>
                <div className="profiledatabig bold green">{customer?.availableBalance}</div>
                <div className="profilelabel">Total Balance</div>
                <div className="profiledatabig">{customer?.totalBalance}</div>
            </div>
            <div className="profilegrid3">
                <div className="profilelabel">Address</div>
                <div><input defaultValue={customer?.address?.street} className="editinplace" data-key="address.street" onChange={ handleChange }/></div>
                <div className="profilelabel">City</div>
                <div><input defaultValue={customer?.address?.locality} className="editinplace" data-key="address.locality" onChange={ handleChange }/></div>
                <div className="profilelabel">State</div>
                <div><input placeholder={customer?.address?.region} className="editinplace" data-key="address.region" onChange={ handleChange }/></div>
                <div className="profilelabel">Country</div>
                <div><input placeholder={customer?.address?.country} className="editinplace" data-key="address.country" onChange={ handleChange }/></div>
                <div className="profilelabel">Postal Code</div>
                <div><input placeholder={customer?.address?.postalCode} data-key="address.postalCode" onChange={ handleChange }/></div>
                <div className="profilelabel">Mobile</div>
                <div><input placeholder={customer?.mobile} data-key="mobile" onChange={ handleChange }/></div>
                <div className="profilelabel">Phone</div>
                <div><input placeholder={customer?.phoneNumber} data-key="phoneNumber" onChange={ handleChange }/></div>
                <div className="profilelabel">Date of Birth</div>
                <div>
                    <Space direction="vertical">
                        <DatePicker
                            placeholder="Select Date"
                            format={'YYYY-MM-DD'}
                            defaultValue={defaultDOB}
                            disabledDate={d => !d || d.isAfter(moment().subtract(18, 'years')) }
                            onChange={ handleChangeDOB } />
                    </Space>
                </div>
                <div className="profilelabel">Gender</div>
                <div>
                    <select data-key="gender" defaultValue={customer?.gender} onChange={ handleChange }>
                        <option value=""></option>
                        <option value="female">Female</option>
                        <option value="male">Male</option>
                        <option value="other">Other</option>
                        <option value="notinformed">Prefer not to say</option>
                    </select>
                </div>
                <div className="profilelabel">Occupation</div>
                <div><input placeholder={customer?.occupation} data-key="occupation" onChange={ handleChange }/></div>
                <div className="profilelabel">Industry</div>
                <div><input placeholder={customer?.industry} data-key="industry" onChange={ handleChange }/></div>
            </div>
            <div className="profilegrid4">
                <div className="profilelabel">Account Enabled</div>
                <div><select data-key="enabled" defaultValue={customer?.enabled ? "Yes" : "No"} onChange={ handleChange }>
                        <option value=""></option>
                        <option value={"Yes"}>Yes</option>
                        <option value={"No"}>No</option>
                    </select>
                </div>
                {(editCustomer?.accountStatus === "Closed") &&
                    <>
                    <div className="profilelabel">Closed Reason</div>
                    <div>
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            defaultValue={customer?.accountStatusReason}
                            onChange={handleChangeAccountStatusReason}>
                            {acctStatReasonOptions}
                        </Select>
                    </div>
                    </>
                }
                {(editCustomer?.accountStatus === "Pending" || (customer?.requiredActions && customer?.requiredActions.length > 0)) &&
                    <>
                    <div className="profilelabel">Required User Actions</div>
                    <div>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            defaultValue={customer?.requiredActions}
                            onChange={handleChangeRequiredActions}>
                            {reqActionOptions}
                        </Select>
                    </div>
                    </>
                }

                <div className="profilelabel">Realm</div>
                <div>{customer?.realm}</div>
                <div className="profilelabel">Sign Up Date</div>
                <div>{customer?.registrationDate}</div>
                <div className="profilelabel">Sign Up Status</div>
                <div className={customer?.registrationStatus === "Complete" ? "green":""}>
                    {customer?.registrationStatus}</div>
                <div className="profilelabel">ID Verification</div>
                <div>{customer?.verificationId}</div>
                <div className="profilelabel">Document</div>
                <CustomerDocumentsList userDocuments={customer?.documents}/>
                <div className="profilelabel">VIP Level</div>
                <div>
                    <select data-key="vipLevel" defaultValue={customer?.vipLevel} onChange={ handleChange }>
                        <option value="None"></option>
                        <option value="Preferred">Preferred</option>
                        <option value="Custom">Custom</option>
                    </select></div>
                <div className="profilelabel">Flag</div>
                <div>
                    <select data-key="flag" defaultValue={customer?.flag} onChange={ handleChangeFlag }>
                        <option value={false}></option>
                        <option value={true}>Flag</option>
                    </select>
                </div>
                <div className="profilelabel">Access to Buy+Send</div>
                <div>
                    <select data-key="isDeniedBuyAndSend" defaultValue={customer?.isDeniedBuyAndSend} onChange={ handleChangeAccessBuyAndSend }>
                        <option value={false}>Allowed</option>
                        <option value={true}>Denied</option>
                    </select>
                </div>
                <div className="profilelabel">Allowed Withdrawals</div>
                <div>
                    <select data-key="isDeniedWithdraw" defaultValue={customer?.isDeniedWithdrawals} onChange={ handleChangeAllowedWithdrawals }>
                        <option value={false}>Allowed</option>
                        <option value={true}>Denied</option>
                    </select>
                </div>
                <div className="profilelabel">IP Address</div>
                <div>{customer?.ipAddress}</div>
                <div className="profilelabel">Device ID</div>
                <div className="profilelabeldeviceid">{customer?.deviceId}</div>
            </div>
            <div className="profilegrid5">
                <button className="buttonprimary primary savecancel" onClick={(e) => {saveCustomer(e)}}>Save</button>
                <button className="buttonprimary secondary savecancel cancel" onClick={(e) => {handleCancel(e)}}>Cancel</button>
            </div>
        </div>
        }
    </>
}

export default CustomerProfileEditPage;
